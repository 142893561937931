@media only screen and (max-width: 767px) {
    .home2-content {
        display: block;
    }

    .banner-text-large {
        font-size: 35px;
        line-height: 60px;
    }

    .banner-text-small {
        font-size: 18px;
        line-height: 26px;
    }

    .text-large {
        font-size: 35px;
        line-height: 45px;
    }

    .text-small {
        font-size: 16px;
        line-height: 20px;
    }

    .feature-box {
        position: relative;
        top: unset !important;
        bottom: unset !important;
        left: unset !important;
        right: unset !important;
        width: 90%;
        margin: 15px auto;
    }

    .banner {
        height: 95vh;
        min-height: unset;
        align-items: baseline;
    }

    .banner-video {
        height: 60%;
        object-fit: cover;
    }

    .banner-content::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 60%;
        top: 0;
        background: linear-gradient(180deg, transparent, transparent, #091c39);
    }

    .banner-btn-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .banner-btn-group .btn {
        margin: 0 !important;
    }

    .section-1 {
        padding: 75px 0 450px 0;
        background-position-y: bottom;
    }

    .section-2 {
        background-image: unset;

        .company-box {
            display: grid;
            padding-bottom: 100px !important;

            img {
                margin: 25px auto !important;
            }
        }

        .border-image {
            display: none;
        }

        .border-dotted {
            margin: 15px -25px;
            padding: 50px 25px;
            border: 2.5px dotted #1576db;
            border-radius: 40px;
        }
    }

    .section-3 {
        margin-top: 75px;
        padding: 275px 0px;
        background-size: 100%;
        background-position: top;
    }

    .section-4 {
        margin-top: -275px;
        padding: 275px 0 100px 0;
        background-size: 100%;
        background-position: top;
    }

    .section-5 {
        padding: 200px 0 0 0;

        .maritime {
            margin: 100px auto auto auto;
            width: 60%;
        }
    }

    .section-8 {
        .content {
            margin-top: 0;
        }

        .ai-dashboard {
            width: 75px;
            top: 10%;
        }
    }

    .about-section-1 {
        padding: 100px 0 350px 0;

        .bg-1 {
            width: 100%;
        }
    }

    .about-section-2 {
        .mission-card {
            margin: 25px 0;
        }
    }

    .section-7 {
        padding: 300px 0 300px 0;
        background-size: 100%;
        background-position: top;
        background-repeat: no-repeat;
    }

    .industries-section-1 {
        .bg-1 {
            display: none;
        }
    }

    .industries-section-2 {
        padding: 200px 0 150px 0;

        .bg-1 {
            top: -50px;
        }
    }

    .industries-section-3 {
        padding-top: 200px;

        .bg-1 {
            top: -100px;
            width: 100%;
        }
    }

    .industries-section-4 {
        padding-top: 400px;

        .bg-1 {
            width: 100%;
        }
    }

    .industries-section-5 {
        padding: 300px 0 0 0;

        .bg-1 {
            width: 100%;
        }
    }

    .about-section-3 {
        padding: 100px 0 200px 0;
    }

    .section-9 {
        .background-2 {
            width: 100%;
        }

        .background-3 {
            width: 100px;
            height: unset;
            margin: 50px auto;
            display: flex;
        }
    }

    .products-section-1 {
        padding-bottom: 300px;

        .bg-1 {
            width: 100%;
        }
    }

    .products-section-2 {
        padding-bottom: 325px;

        .bg-1 {
            width: 100%;
        }
    }

    .products-section-3 {
        .gtai-connect {
            padding-top: 300px;
        }

        .bg-1 {
            top: 32%;
        }
    }

    .products-section-4 {
        padding-top: 100px;

        .bg-1 {
            position: unset;
            width: 100%;
            max-width: unset;
        }

        .bg-2 {
            width: 80px;
            top: 140px;
            left: 50px;
        }
    }

    .products-section-5 {
        padding-top: 250px;

        .bg-1 {
            width: 100%;
        }
    }


    .contact-section-1 {
        padding-top: 0;

        .contact-info-box {
            margin: 50px 0 !important;
            padding: 0 !important;
        }

        .contact-form-box {
            margin-top: 50px;
            padding: 25px 15px;
            text-align: center;
        }
    }

    footer {
        padding: 350px 0 0 0;

        .nav {
            width: unset;
            display: flex;
            align-items: center;
            justify-content: center;

            .nav-link {
                margin: unset;
            }
        }

        .sticky-content {
            padding: 15px;
            flex-direction: column;

            .lebel-1 {
                font-size: 25px;
                line-height: 28px;
                text-align: center;
            }

            .lebel-2 {
                font-size: 28px;
                line-height: 30px;
                text-align: center;
            }
        }
    }
}