$primary: #0480e7;
$success: #0be096;
$dark: #0c2b62;

$btn-border-radius: 50px;
$btn-padding-x: 50px;
$btn-padding-y: 18px;
$btn-font-size: 14px;
$btn-font-weight: 600;

@import '~bootstrap/scss/bootstrap.scss';

.btn {
    text-transform: uppercase;
}

.btn-primary,
.btn-primary :hover {
    color: #fff !important;
}

.btn-dark:hover {
    background-color: $dark;
    opacity: 0.9;
    transition: 0.3s;
}

@media only screen and (max-width: 767px) {
    .row>* {
        padding: 0 25px;
    }

    .btn {
        padding: 18px 25px;
    }
}