body {
  color: #fff;
  background-color: #0b1f40;
  font-family: 'DM Sans';
}

// Header CSS START

.App-logo {
  width: 100px;
}

.hamburger {
  padding: 18px;
  background: none;
  border: none;
}

.hamburger:hover,
.hamburger:active,
.hamburger:focus-visible {
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
}

.hamburger .line-1,
.hamburger .line-2,
.hamburger .line-3 {
  margin: 8px 0;
  width: 35px;
  height: 4px;
  background: #fff;
  border-radius: 2px;
}

.hamburger .line-2 {
  width: 40px;
}

.hamburger .line-3 {
  width: 30px;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 4px 0;
  transition: 0.3s;
  z-index: 999;
}

.navbar.active {
  background-color: #0b1f40b8;
}

.navbar>.container {
  margin-right: 0;
  max-width: unset;
}

.navbar-nav {
  align-items: center;
}

.nav-link {
  position: relative;
  margin-left: 30px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.23px;
  letter-spacing: 0.04em;
  text-align: left;
  text-transform: uppercase;
}

.nav-link:focus,
.nav-link:hover,
.nav-link.active {
  color: #fff !important;
}

.nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 8px;
  width: 00px;
  height: 2px;
  background: #fff;
  transition: 0.3s;
}

.nav-link.active::after {
  background: #0be096;
  width: 20px;
}

.nav-link:hover::after {
  width: 20px;
}

.navbar-brand .nav-link {
  margin-left: 0;
}

.navbar-brand .nav-link::after {
  content: unset;
}

.nav-button {
  padding: 15px 35px !important;
  width: max-content;
  background: rgba(4, 128, 231, 1);
  border-radius: 50px;
}

.nav-button::after {
  left: 35px !important;
  bottom: 7px !important;
}

.offcanvas {
  max-width: 75%;
  background-color: #0a4074;

  .nav {
    flex-direction: column;
  }

  .nav-link {
    margin: 15px;
    padding: 8px;
  }
}

.offcanvas-header {
  padding: 35px;
}

// Header CSS END

// Footer CSS START

footer {
  position: relative;
  padding: 150px 0 0 0;
  background-color: #081f3d;
  text-align: center;

  .sticky-content {
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
    margin: auto;
    padding: 25px;
    width: max-content;
    background: #0480e7;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    z-index: 10;

    .lebel-1 {
      font-size: 40px;
      font-weight: 700;
      line-height: 52.08px;
      letter-spacing: -0.03em;
      text-align: left;
    }

    .lebel-2 {
      font-size: 50px;
      font-weight: 700;
      line-height: 65.1px;
      letter-spacing: -0.03em;
      text-align: left;
    }
  }

  .App-logo {
    width: 120px;
  }

  .nav {
    width: max-content;
    margin: auto;
    margin-top: 40px;

    .nav-link {
      color: #54729f;
      text-transform: capitalize;
      cursor: pointer;
    }

    .nav-link::after,
    .nav-link:hover::after {
      width: 0px;
    }
  }

  .social-icon {
    position: relative;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    hr {
      position: absolute;
      left: 0;
      right: 0;
    }

    .social-link {
      margin: auto;
      padding: 0 10px;
      width: max-content;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      background: #081f3d;
      z-index: 9;

      div {
        width: 55px;
        height: 55px;
        border: 1px solid #31486b;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .copyright {
    padding: 30px 0;
    color: #3f5473;
    font-size: 14px;
    font-weight: 300;
    line-height: 18.23px;
    text-align: center;

    span {
      color: #fff;
    }

    a {
      color: inherit;
      text-decoration: none;
      transition: 0.3s;

      &:hover {
        color: #fff;
      }
    }
  }
}

// Footer CSS END

.banner {
  position: relative;
  height: 100vh;
  min-height: 700px;
  // background-image: url(./../assets/images/bg1.svg);
  // background-size: cover;
  display: flex;
  align-items: center;
}

.banner-video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.banner-content {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #0000004d, #0c1f41);
  display: flex;
  align-items: center;

  .container {
    z-index: 9;
  }
}

.home2 .banner-content {
  align-items: end;
}

.home2-content {
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home3 {
  .banner-video-container {
    position: absolute;
    width: 60%;
    height: 100%;
    right: 0;
  }

  .banner-video {
    object-fit: cover;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #0b1f40, transparent);
  }

  .banner-content {
    background: linear-gradient(0deg, #0b1f40, transparent, transparent);
  }
}

.home4 {
  .banner-video-container {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
  }

  .banner-video {
    object-fit: cover;
  }

  .banner-content {
    background: none;
  }
}

.banner-text-large {
  font-size: 70px;
  font-weight: 600;
  line-height: 109.37px;
  letter-spacing: -0.05em;
}

.banner-text-small {
  font-size: 22px;
  font-weight: 300;
  line-height: 32.55px;
  letter-spacing: -0.03em;
}

.text-large {
  font-size: 60px;
  font-weight: 400;
  line-height: 70px;
  letter-spacing: -0.02em;
}

.text-medium {
  font-size: 28px;
  font-weight: 400;
  line-height: 39.06px;
}

.text-small {
  color: rgba(126, 135, 166, 1);
  font-size: 18px;
  font-weight: 400;
  line-height: 26.04px;
  letter-spacing: 0.01em;
}

.small-title {
  font-weight: 600;
  line-height: 23.44px;
  letter-spacing: 0.5600000023841858px;
  color: #0480E7;
}

.section-1 {
  min-height: 80vh;
  padding: 200px 0px;
  background-image:
    url(./../assets/images/section-1-bg-1.svg),
    url(./../assets/images/section-1-bg-2.svg);
  background-position: left, right;
  background-repeat: no-repeat;
  background-size: contain;
}

.section-2 {
  min-height: 80vh;
  background-image: url(./../assets/images/section-2-bg-1.svg);
  background-size: cover;
  background-position: center;

  .company-box {
    padding-bottom: 300px;
  }
}

.border-image {
  position: absolute;
  width: 95%;
  left: 0;
  right: 0;
  margin: auto;
}

.border-dotted {
  padding: 50px 0;
}

.section-3 {
  padding: 200px 0px;
  background-image: url(./../assets/images/section-3-bg-1.svg);
  background-size: 75%;
  background-position: right;
  background-repeat: no-repeat;
}

.section-4 {
  margin-top: -300px;
  padding: 200px 0 100px 0;
  background-image: url(./../assets/images/section-4-bg-1.svg);
  background-size: 75%;
  background-position: left;
  background-repeat: no-repeat;
}

.section-5 {
  margin-top: -200px;
  padding: 200px 0;
  background-image: url(./../assets/images/section-5-bg-1.svg);
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
}

.stats .title {
  font-size: 100px;
  font-weight: 500;
  line-height: 130.2px;
}

.stats .sub-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 26.04px;
}

.section-7 {
  margin-top: -50px;
  margin-bottom: -200px;
  padding: 200px 0;
  background-image: url(./../assets/images/section-7-bg-1.svg);
  background-size: cover;
  background-position: bottom;
}

.section-8 {
  padding: 100px 0 200px 0;
}

.section-8 .content {
  margin-top: -150px;
}

.ai-dashboard {
  width: 300px;
  position: absolute;
  top: 18%;
  left: 15%;
  margin: auto;
}

.section-9 {
  position: relative;
  padding: 200px 0;
  background-image: url(./../assets/images/section-9-bg-1.svg);
  background-size: cover;
  background-position: bottom;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, transparent, transparent, #0b2348);
  }
}

.section-9 .background-2 {
  position: absolute;
  left: 0;
  top: -15%;
  width: 75%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.section-9 .background-3 {
  height: 450px;
  margin-top: 40px;
}

.feature-box {
  position: absolute;
  width: 430px;
  border: 2px dotted rgba(4, 128, 231, 1);
  border-radius: 15px;

  &.box-1 {
    top: 7%;
    left: 8%;
  }

  &.box-2 {
    top: 40%;
    left: 4%;
  }

  &.box-3 {
    top: 73%;
    left: 11%;
  }

  &.box-4 {
    top: 22%;
    right: 0%;
  }

  &.box-5 {
    top: 54%;
    right: 2%;
  }

  .title-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -25px;
    margin: auto;
    width: 50px;
    height: 50px;
    background: rgb(5 128 231);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .text-small {
    padding: 15px 15px 15px 45px;
    font-size: 16px;
  }
}

.industries {
  position: relative;

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 30px 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    text-align: center;

    .title {
      font-size: 26px;
      font-weight: 400;
      line-height: 33.85px;
      letter-spacing: -0.02em;
    }

    .value {
      color: #5a5a5a;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.page-banner {
  margin-top: 125px;

  .page-title {
    font-size: 70px;
    font-weight: 600;
    line-height: 91.14px;
    letter-spacing: -0.05em;
    text-align: center;
  }

  .page-subtitle {
    font-size: 25px;
    font-weight: 300;
    line-height: 32.55px;
    letter-spacing: -0.03em;
    text-align: center;
  }
}

.about-section-1 {
  padding: 100px 0;

  .bg-1 {
    position: absolute;
    right: 0;
    width: 60%;
    z-index: -1;
  }

  .bg-2 {
    position: absolute;
    right: 0;
    width: 60%;
    z-index: -2;
  }
}

.about-section-2 {
  background-image:
    url(./../assets/images/about-section-2-bg-1.svg),
    url(./../assets/images/about-section-2-bg-2.svg);
  background-position: left, right;
  background-repeat: no-repeat;
  background-size: contain;
}

.about-section-3 {
  position: relative;
  padding: 300px 0 200px 0;
  z-index: 1;

  .bg-1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    filter: opacity(0.3);
    z-index: -1;
  }
}

.contact-section-1 {
  padding: 100px 0;

  .bg-1 {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    z-index: -1;
  }

  a {
    color: inherit;
  }
}

.list-icon-tick {
  padding-left: 25px;
  background-image: url(./../assets//images/list-icon-tick.svg);
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 2px 4px;
}

.list-icon-map {
  padding-left: 25px;
  background-image: url(./../assets//images/list-icon-map.svg);
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: 0px 4px;
}

.contact-form-box {
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 40px;
  box-shadow: 2px 4px 20px 20px rgba(41, 40, 40, 0.1);

  .text-small {
    color: #fff;
  }

  .form-floating {
    margin: 8px 0;

    label {
      color: rgba(255, 255, 255, 0.4) !important;
      padding-left: 20px;

      &::after {
        color: rgba(255, 255, 255, 0.4) !important;
        background-color: transparent !important;
      }
    }

    .text-danger {
      margin-left: 20px;
    }
  }

  .form-control {
    padding: 15px 20px;
    color: #fff;
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid rgba(255, 255, 255, 0.4);

    :focus {
      color: #fff;
      background-color: transparent;
    }
  }

  textarea {
    &.form-control {
      border-radius: 20px;
      resize: none;
    }
  }
}

// .form-control::-webkit-input-placeholder {
//   color: rgba(255, 255, 255, 0.4) !important;
// }

// .form-control::-moz-placeholder {
//   color: rgba(255, 255, 255, 0.4) !important;
// }

// .form-control::-ms-placeholder {
//   color: rgba(255, 255, 255, 0.4) !important;
// }

// .form-control::placeholder {
//   color: rgba(255, 255, 255, 0.4) !important;
// }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.industries-section-1 {
  position: relative;
  padding: 100px 0;

  .bg-1 {
    position: absolute;
    right: 0;
    bottom: 50px;
  }
}

.industries-section-2 {
  position: relative;
  padding: 100px 0 150px 0;

  .bg-1 {
    position: absolute;
    left: 0;
    bottom: 50px;
  }

  .bg-2 {
    position: absolute;
    left: 0;
    top: -20%;
    width: 50%;
    z-index: -1;
  }

  .bg-3 {
    position: absolute;
    top: -50%;
    right: 0;
    width: 50%;
    z-index: -1;
  }

  .bg-4 {
    position: absolute;
    top: -30%;
    right: 0;
    width: 50%;
    z-index: -1;
  }
}

.industries-section-3 {
  position: relative;

  .bg-1 {
    position: absolute;
    top: 20%;
    right: 0;
    width: 67%;
    z-index: -1;
  }
}

.industries-section-4 {
  position: relative;
  padding: 200px 0 100px 0;

  .bg-1 {
    position: absolute;
    left: 0;
    top: 5%;
    width: 55%;
  }
}

.industries-section-5 {
  position: relative;
  padding: 50px 0 0 0;

  .bg-1 {
    position: absolute;
    top: 0%;
    right: 0;
    width: 60%;
  }
}

.products-section-1 {
  position: relative;
  padding: 50px 0;

  .bg-1 {
    position: absolute;
    right: 0;
    bottom: -5%;
    width: 55%;
    z-index: -1;
  }
}

.products-section-2 {
  position: relative;
  padding: 100px 0 150px 0;

  .bg-1 {
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 55%;
  }

  .bg-2 {
    position: absolute;
    left: 0;
    top: -20%;
    width: 50%;
    z-index: -1;
  }

  .bg-3 {
    position: absolute;
    top: -50%;
    right: 0;
    width: 50%;
    z-index: -1;
  }

  .bg-4 {
    position: absolute;
    top: -30%;
    right: 0;
    width: 50%;
    z-index: -1;
  }
}

.products-section-3 {
  position: relative;

  .bg-1 {
    position: absolute;
    top: 10%;
    right: 0;
    z-index: -1;
  }

  .bg-2 {
    position: absolute;
    top: 41%;
    left: 48%;
    width: 20%;
  }
}

.products-section-4 {
  position: relative;
  padding: 200px 0 100px 0;

  .bg-1 {
    position: absolute;
    left: 0;
    width: 800px;
    max-width: 55%;
  }

  .bg-2 {
    position: absolute;
    top: 40%;
    left: 7%;
    margin: auto;
    width: 220px;
  }
}

.products-section-5 {
  position: relative;
  padding: 50px 0 0 0;

  .bg-1 {
    position: absolute;
    top: 0%;
    right: 0;
    width: 60%;
    z-index: -1;
  }
}

.full-page-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: #0000003d;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  padding: 50px;
  border-radius: 20px;
}

.modal-title {
  color: #000;
  font-weight: 600;
}

.modal-header .btn-close {
  position: absolute;
  right: 25px;
  top: 25px;
  border: 1px solid gray;
  border-radius: 50%;
  font-size: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #33375b inset !important;
  -webkit-text-fill-color: #fff !important;
}

.border-dashed {
  padding: 60px;
  border: 2px dashed #0480E7;
  border-radius: 15px;
}

.description-title {
  font-size: 36px;
  line-height: 46.87px;
  letter-spacing: 0.56px;
}

.text-justify {
  text-align: justify;
}

.more {
  margin-top: 50px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 26.04px;
  letter-spacing: 0.01em;
  user-select: none;
  cursor: pointer;
}

.product-section-2 {
  padding: 80px 0 0 0;
}

.nav-tab-container {
  .nav-tabs {
    border: 0;

    .nav-item {
      margin-bottom: -2px;
      display: flex;
      align-items: end;

      &:not(:first-child) {
        margin-left: 15px;
      }

      .nav-link {
        margin: 0;
        padding: 10px 30px;
        color: #7E87A6;
        background: transparent;
        border: 2px dashed #0480E7;
        border-bottom: none;
        border-radius: 20px 20px 0 0;
        font-size: 20px;
        line-height: 31.25px;
        letter-spacing: 0.56px;
        text-transform: capitalize;

        &::after {
          background: unset;
        }

        &.active {
          padding: 20px 40px;
          color: #fff;
          background: linear-gradient(180deg, #2564d4, #163977);
          font-size: 24px;
        }
      }
    }
  }

  .tab-content {
    background: linear-gradient(180deg, #163977, #0C1F41 6%);
    border: 2px dashed #0480E7;
    border-radius: 0 20px 20px 20px;
  }
}

.plans {
  padding: 35px;
  text-align: center;

  .plan-card {
    padding: 100px 40px 40px 40px;
    border: 2px dashed #0480E7;
    border-radius: 20px;

    .plan-price {
      font-size: 48px;
      font-weight: 600;
      line-height: 62.5px;
      letter-spacing: 0.56px;

      span {
        font-size: 20px;
        font-weight: 400;
        line-height: 26.04px;
        letter-spacing: 0.56px;
      }
    }

    .plan-name {
      font-size: 24px;
      font-weight: 300;
      line-height: 31.25px;
      letter-spacing: 0.56px;
    }

    .plan-type {
      font-size: 32px;
      font-weight: 600;
      line-height: 41.66px;
      letter-spacing: 0.56px;
    }

    .plan-for {
      margin: 40px 0 30px 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 20.83px;
      letter-spacing: 0.56px;
    }

    .plan-description {
      margin: 20px 0;
      color: #7E87A6;
      font-size: 18px;
      font-weight: 400;
      line-height: 23.44px;
      letter-spacing: 0.56px;
    }
  }
}

.cart-table {

  th,
  td {
    padding: 15px;
    color: #fff;
    background-color: transparent;
    font-size: 24px;
    font-weight: 600;
    line-height: 31.25px;
    letter-spacing: 0.56px;
    border-bottom: 1px dashed #0480E7;

    &:last-child {
      padding-right: 0;
    }
  }

  td {
    padding: 35px 15px;
    font-weight: 500;
  }

  .card-product-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.83px;
    letter-spacing: 0.56px;
  }

  .card-product-description {
    color: #7E87A6;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.44px;
    letter-spacing: 0.56px;
  }
}

.quantity.form-select {
  --bs-form-select-bg-img: url(./../assets/images/chevron-down.svg);
  width: 100px;
  color: #fff;
  background-color: transparent;
  background-size: 20px;
  border: 1px solid #016FD0;
  cursor: pointer;
}

.cart-form-box {
  .form-name {
    margin: 25px 0 15px 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 31.25px;
    letter-spacing: 0.56px;
  }

  .form-border {
    padding: 25px;
    border-radius: 20px;
    border: 1px dashed #0480E7
  }

  .form-container {
    gap: 20px 0;
  }

  .form-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.83px;
    letter-spacing: 0.56px;
  }

  .form-control {
    padding: 10px 12px;
    color: #fff;
    background-color: transparent;
    border: 1px solid #0480E7;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.23px;
    letter-spacing: 0.56px;

    &::placeholder {
      color: #7E87A6 !important;
    }
  }

  .form-check {
    .form-check-label {
      width: 100%;
      cursor: pointer;
      user-select: none;
    }
  }

  .delivery-methods {
    gap: 8px;

    .form-check {
      padding: 12px 15px 12px 40px;
      border: 1px solid #0480E7;
      border-radius: 10px;
    }

    .delivery-name {
      font-size: 16px;
      font-weight: 600;
      line-height: 20.83px;
      letter-spacing: 0.56px;
    }

    .delivery-time {
      color: #7E87A6;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.23px;
      letter-spacing: 0.56px;
    }

    .delivery-price {
      font-size: 16px;
      font-weight: 600;
      line-height: 20.83px;
      letter-spacing: 0.56px;
    }
  }
}

a {
  cursor: pointer;
}

.remove-item {
  transition: 0.3s;

  &:hover {
    scale: 1.1;
  }
}